<template>
  <div>
    <div class="bg-danger-style">
      <div style="width: 380px;text-align: right;display: inline-block;">
        <!-- 查询 -->
        <el-form :model="form" ref="form" :inline="true" :label-position="labelPosition" label-width="80px" class="demo-ruleForm">
          <el-form-item label="标段名称">
            <el-cascader
              v-model="bidId"
              placeholder="请选择"
              :options="bidList"
              :show-all-levels="false"
              filterable
              style="width: 270px"
              @change="onDeviceNameList"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="车牌" prop="deviceId">
            <el-select v-model="deviceId" 
                       placeholder="请选择车牌" 
                      
                       @change="onDeviceId($event)" 
                       filterable 
                       style="width: 270px">
              <el-option
                      v-for="(item, idx) in carList"
                      :key="idx"
                      :label="item.label"
                      :value="item.device"
                      :disabled="item.deviceStatus==='无设备'">
              </el-option>
            </el-select>
            <!-- <el-input v-model="name" placeholder="车牌号/设备号/SIM卡号"></el-input> -->
          </el-form-item>
          <el-form-item label="时间设置" prop="videoDate">
            <el-date-picker
              v-model="videoDate"
              type="date"
              value-format="yyyy-MM-dd"
              @change="dateInquire"
              :clearable ='false'
              placeholder="选择日期"
              style="width: 270px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="时间段" prop="videoDate">
            <el-time-picker
              is-range
              v-model="specificTime"
              range-separator="至"
              start-placeholder="开始时间"
              value-format="HH:mm:ss"
              end-placeholder="结束时间"
              @change="specificTimeInquire"
              :default-time="['00:00:00', '23:59:59']"
              placeholder="选择时间范围"
              :clearable ='false'
              style="width: 270px">
            </el-time-picker>
          </el-form-item>
          <el-form-item label="存储位置" prop="list">
            <el-select v-model="store" placeholder="请选择" style="width: 270px">
              <el-option v-for="(item, idx) in form.list"
                         :key="idx"
                         :label="item.label"
                         :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item label="码流类型" prop="streamType">
            <el-select v-model="stream" placeholder="请选择" style="width: 270px">
              <el-option v-for="(item, idx) in form.streamType"
                         :key="idx"
                         :label="item.label"
                         :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item label="音视频" prop="typeAAV">
            <el-select v-model="res" placeholder="请选择" style="width: 270px">
              <el-option v-for="(item, idx) in form.typeAAV"
                         :key="idx"
                         :label="item.label"
                         :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="clickInquire" :loading="tableLoading">查 询</el-button>
          </el-form-item>
        </el-form>
        <!-- 查询 END-->
      </div>

      <!-- <div style="width: 100%; height: 500px">
      </div> -->

      <!-- 视频模块 -->
      <div class="main-video">
        <div class="video" style="height: 510px">
          <history-video v-for="(item, idx) in allVideoUrl" :key="idx" :style="mainStyle" :url="item" ref="rtmp"></history-video>
        </div> 
        <div class="video-operation">
        <!-- <div>
          <i class="iconfont curp" v-for="(item, idx) in iconList"
             :class="[item.name, screenNum===item.value?'active-icon': '']"
             :key="idx"
             @click="clickSwitch(item.value)"></i>
        </div> -->
      </div>
    </div>
  </div>
      <!-- 视频模块END -->


    <div class="bg-danger-style">
       <!-- 列表 -->
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- <el-tab-pane label="作业信息" name="jobInformation">
            <ayl-table :table="jobInformationTable" style="margin-top: 10px">
            </ayl-table>
          </el-tab-pane> -->
          <el-tab-pane label="视频列表" name="file">
           <el-table :data="videoHistoryData" v-loading="tableLoading" height="550" style="margin-top: 10px;" :header-cell-style="{background:'rgba(250,250,250,1)'}">
            <el-table-column label="车牌号码" prop="plateNumber" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.plateNumber }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="通道号" width="80">
              <template slot-scope="scope">
                <span>{{ chnData(scope.row.channel) }}</span>
              </template>
            </el-table-column> -->
            <el-table-column label="排班驾驶员" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.arrangeDriver }}</span>
              </template>
            </el-table-column>
            <el-table-column label="实际驾驶员" width="120">
              <template slot-scope="scope">
                <span>{{ scope.row.realDriver }}</span>
              </template>
            </el-table-column>
            <el-table-column label="所属企业" width="210">
              <template slot-scope="scope">
                <span>{{ scope.row.companyName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="所属标段" width="200">
              <template slot-scope="scope">
                <span>{{ scope.row.bidName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="媒体类型" width="80">
              <template slot-scope="scope">
                <span>{{ scope.row.res }}</span>
              </template>
            </el-table-column>
            <el-table-column label="存储类型" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.storage }}</span>
              </template>
            </el-table-column>
            <el-table-column label="码流类型" prop="stream" ></el-table-column>
            <el-table-column label="大小(MB)" width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.fileSize + 'MB'}}</span>
              </template>
            </el-table-column>
            <el-table-column label="开始时间" width="180">
              <template slot-scope="scope">
                <span>{{ formatDate(scope.row.beginTime)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="结束时间" width="180">
              <template slot-scope="scope">
                <span>{{ formatDate(scope.row.endTime)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100" fixed="right">
              <template slot-scope="scope">
                <div style="color:#1ABC9C" @click="playVideo(scope.row, scope.$index)" class="curp" >{{ scope.row.status?'重新播放':'播放'}}</div>
              </template>
            </el-table-column>
          </el-table>
          </el-tab-pane>
          <el-tab-pane label="报警列表" name="alarmList">
            <ayl-table :table="alarmTable" style="margin-top: 10px">
            </ayl-table>
          </el-tab-pane>
          <el-tab-pane label="下载状态" name="downloadStatus">
            <ayl-table :table="statusData" style="margin-top: 10px">
            </ayl-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 列表END -->
    </div>
    <!-- 查看附件 -->
    <el-dialog title="查看附件" :visible.sync="dialogFormVisible">
      <div class="attachment-main">
        <div class="left-img">
          <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" style="width: 100%"
                 v-if="videoIdx === 1"></video>
          <img :src="url===''?playerOptions.images[0]:url" class="images" v-image-preview v-else>
        </div>
        <div class="right-img">
          <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" class="images"
                 @click="videoItem(1)"></video>
          <img v-for="(item, idx) in playerOptions.images" :key="idx" @click="chg(idx,0)" :src="item" class="images">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import historyVideo from './videoPlay'
  const LineIcon = require('../../../../assets/images/map-icon (1).png')
  const offLineIcon = require('../../../../assets/images/map-icon (2).png')
  const onLineIcon = require('../../../../assets/images/map-icon (3).png')
  export default {
    name: 'videojs',
    components: {
      // eslint-disable-next-line vue/no-unused-components
      historyVideo,
    },
    data () {
      const vm = this
      return {
        nav: [{name: "历史回放", path: ""}, {name: "视频回放", path: ""}],
        iconList: [{
          name: 'iconicon-test2',
          value: 1
        }, {
          name: 'iconicon-test3',
          value: 4
        }, {
          name: 'iconicon-test9',
          value: 9
        }],
        //标段数据
        bidId: this.$route.query.bidId || null,
        bidList: [],
        deviceIdList: null,    //deviceId


        // 视频数据
        screenNum: 1,
        allVideoUrl: [],

        labelPosition: 'right',
        //车牌号列表
        carList: [],
        deviceId: this.$route.query.deviceId||'',
        videoDatas: null,

        activeName: 'file',
        plateNumber: null,

        // 车辆
        videoDate: new Date(),
        InquireYear: null,
        InquireMon: null,
        InquireDay: null,
        videoHistoryData: [],
        tableLoading: false,

        //拼接开始时间、结束时间
        newStartTime: null,
        newEndTime: null,

        specificTime: ['00:00:00', '23:59:59'],
        start: 0,
        end: 86399,
        chn: 0,
        store: 0,
        name: null,
        amr1: 0,
        amr2: 0,
        stream: 0,
        res: 0,
        DevIDNO: null,

        form: {
          //通道号
          channel:[
            { label: '全部', value: 0},
            { label: '通道一', value: 1},
            { label: '通道二', value: 2},
            { label: '通道三', value: 3},
            { label: '通道四', value: 4},
            { label: '通道五', value: 5},
            { label: '通道六', value: 6},
          ],
          // 存储位置
          list: [
            {
              label: '主或者灾备存储器',
              value: 0
            },{
              label: '主存储器',
              value: 1
            },{
              label: '灾备存储器',
              value: 2
          }],
          // 码流类型
          streamType: [
            {
              label: '主或子码流',
              value: 0
            },{
              label: '主码流',
              value: 1
            },{
              label: '子码流',
              value: 2
            }
          ],
          // 音视频资源类型
          typeAAV: [
            {
              label: '音视频',
              value: 0
            },{
              label: '音频',
              value: 1
            },{
              label: '视频',
              value: 2
            },{
              label: '视频或者音视频',
              value: 3
            }
          ],
        },

        //查看附件
        dialogFormVisible: false,
        url: '',
        videoUrl: '',
        videoIdx: 1,
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [],
          images: [],
          poster: '', //你的封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true  //全屏按钮
          }
        },


        value: [],
        //作业信息
        jobInformationTable: {
          // api 查询当前列表页数据的全局方法
          api: vm.$api_hw.historyVideo_listWorkInfo,
          // query 查询当前列表页数据的参数
          query: null,
          // query: {
          //   bidId: this.bidId[1],
          //   objectType: this.form.applicationObject,
          //   startTime: this.form.startTime,
          //   endTime: this.form.endTime,
          // },
          columns: [
            {
            title: '序号',
            width: '50px',
            $index: 'index',
          }, {
              title: '姓名/车牌号',
              width: '120px',
              key: 'name',
          }, {
              title: '所属企业',
              width: '180px',
              key: 'companyName',
          }, {
            title: '所属标段',
            width: '160px',
            key: 'bidName',
          }, {
            title: '所谓网格/线路',
            width: '130px',
            key: 'gridOrRouteName',
          }, {
            title: '排班驾驶员',
            width: '120px',
            key: 'arrangeDriver',
          }, {
            title: '实际驾驶员',
            key: 'realDriver',
            width: '120px',
          }, {
            title: '日期',
            filter: 'str2ymdhm',
            key: 'date',
            width: '180px',
          }, {
            title: '班次时段',
            key: 'workTime',
            width: '180px',
          }, {
            title: '作业开始时间',
            filter: 'str2ymdhm',
            key: 'workStartTime',
            width: '180px',
          }, {
            title: '作业结束时间',
            filter: 'str2ymdhm',
            key: 'workEndTime',
            width: '180px',
          }, {
            title: '实际作业时长',
            key: 'realWorkTime',
            width: '140px',
          }, {
            title: '应作业里程(km)',
            key: 'shouldWorkMileage',
            width: '130px',
          }, {
            title: '实际作业里程(km)',
            width: '140px',
            key: 'realWorkMileage',
          }]
        },
        // 报警点列表
        alarmTable: {
          hidePagination: true,
          api: vm.$api.LsitAlarmDeal,
          query: null,
          // query: {
          //   deviceId: null,
          //   alarmIdentifierList: null,
          //   alarmType: null,
          //   dealStatus: null,
          //   dealName: null,
          //   startTime: null,
          //   endTime: null,
          //   queryContent: null
          // },
          columns: [{
              title: '序号',
              width: '50px',
              $index: 'index',
            },
            {
              title: '车牌号',
              key: 'plateNumber',
              width: '120px',
            }, {
              title: '所属企业',
              showTooltip: true,
              key: 'company',
            }, {
              title: '所属标段',
              showTooltip: true,
              key: 'bidName',
            }, {
              title: '驾驶员姓名',
              width: '100px',
              key: 'driverName',
            }, {
              title: '报警类型',
              width: '140px',
              key: 'alarmIdentifier',
            }, {
              title: '报警等级',
              width: '100px',
              key: 'alarmType',
            }, {
              title: '报警开始时间',
              key: 'alarmStartTime',
              filter: 'str2ymdhm',
              width: '170px',
            }, {
              title: '处理状态',
              width: '100px',
              key: 'dealStatusDes',
            }, {
              title: '处理人',
              width: '100px',
              key: 'dealUserName',
            }, {
            title: '附件',
            render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view-hw': ctx.row.attache === 1 ? true : false,
                    },
                    on: {
                      click: ctx.row.attache === 1 ? vm.goPreview.bind(this, ctx.row) : vm.goNull.bind(this, ctx.row)
                    }
                  }, ctx.row.attache === 1 ? '预览' :  '暂无')
                ])
              }
          }, {
            title: '操作',
            width: '180px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view-hw': true,
                  },
                  on: {
                    click: vm.goDetail.bind(this, ctx.row)
                  }
                }, '查看详情'),
                // h('span', {
                //   class: {
                //     'table-view': true,
                //   },
                //   on: {
                //     click: vm.viewPosition.bind(this, ctx.index, '报警点')
                //   }
                // }, '查看位置'),
              ])
            }
          }]
        },
        //下载状态table
        statusData: {
          // api 查询当前列表页数据的全局方法
          api: vm.$api.CarInfoByQueryCondition,
          // query 查询当前列表页数据的参数
          query: {
            plateColor: null,
            carStatus: null,
            startTime: '',
            endTime: '',
            queryContent: null,
          },
          columns: [
            {
            title: '任务编号',
            width: '100px',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carInfoVOIOV.plateNumber),
              ])
            }
          }, {
              title: '车牌号',
              width: '120px',
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.carInfoVOIOV.plateColor),
                ])
              }
          }, {
              title: '通道号',
              width: '120px',
              render (h, ctx) {
                return h('span', [
                  h('span', ctx.row.carInfoVOIOV.plateColor),
                ])
              }
          }, {
            title: '开始时间',
            width: '130px',
            render (h, ctx) {
              return h('span', [
                h('span', vm.$dayjs(ctx.row.carInfoVOIOV.serviceEndTime).format('YYYY-MM-DD')),
              ])
            }
          }, {
            title: '结束时间',
            width: '130px',
            render (h, ctx) {
              return h('span', [
                h('span', vm.$dayjs(ctx.row.carInfoVOIOV.serviceEndTime).format('YYYY-MM-DD')),
              ])
            }
          }, {
            title: '下载状态',
            width: '90px',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }, {
            title: '下载进度',
            render (h, ctx) {
              return h('span', [
                h('span', ctx.row.carTeamInfoVOIOV.teamName),
              ])
            }
          }]
        },
      }
    },
    methods:{
      // 处理历史视频返回的时间,为yyyy-MM-dd HH:mm:ss
      //开始时间
      initDate(year, mon, day, beg, end){
        let years = 2000 + year
        let mons = mon.toString()[1] ? mon: '0' + mon
        let days = day.toString()[1] ? day: '0' + day
        let endDays = day.toString()[1] ? day: '0' + day
        if( end > 86400 ){
          endDays = Number(endDays)  + 1
        }
        return years + '-' + mons + '-' + days + ' '+ this.initHours(beg)
      },
      //结束时间
      endDate(year, mon, day, beg, end){
        let years = 2000 + year
        let mons = mon.toString()[1] ? mon: '0' + mon
        let days = day.toString()[1] ? day: '0' + day
        let endDays = day.toString()[1] ? day: '0' + day
        if( end > 86400 ){
          endDays = Number(endDays)  + 1
        }
        return years + '-' + mons + '-' + endDays +' '+  this.initHours(end)
      },
      //时长
      durationData(end, beg){
        let duration = end - beg
        return Number(parseFloat((duration)/60).toFixed(1).slice(0,-1)) + '分钟' + duration%60 + '秒'
      },
      // 通道号
      chnData(chn){
        return { 1:'通道一', 2:'通道二', 3:'通道三', 4:'通道四', 5:'通道五', 6:'通道六',} [chn]
        // switch (chn) {
        //   case 1:
        //       return '通道一'
        //   case 2:
        //       return '通道二'
        //   case 3:
        //       return '通道三'
        //   case 4:
        //       return '通道四'
        //   case 5:
        //       return '通道五'
        //   case 6:
        //       return '通道六'
        //   default:
        //       return chn
        // }
      },
      //文件位置
      locData(loc){
        return { 1: '设备', 2: '存储服务器', 4: '下载服务器',} [loc]
        // switch (loc) {
        //   case 1:
        //     return '设备'
        //   case 2:
        //     return '存储服务器'
        //   case 4:
        //     return '下载服务器'
        //   default:
        //     return loc
        // }
      },
      //存储位置
      storeData(store){
        return { 0: '主或者灾备存储器', 1: '主存储器', 2: '灾备存储器'}[store]
        // switch (store) {
        //   case 0:
        //     return '主或者灾备存储器'
        //   case 1:
        //     return '主存储器'
        //   case 2:
        //     return '灾备存储器'
        //   default:
        //     return store
        // }
      },
      //存储类型
      resData(res){
        return { 0: '音视频', 1: '音频', 2: '视频', 3: '视频或者音视频',} [res]
        // switch (res){
        //   case 0:
        //     return '音视频'
        //   case 1:
        //     return '音频'
        //   case 2:
        //     return '视频'
        //   case 3:
        //     return '视频或者音视频'
        // }
      },
      // 秒转时分秒
      initHours(date){
        let hours = parseInt(date / 3600) > 9 ? parseInt(date / 3600) === 24 ? '00': parseInt(date / 3600): '0' + parseInt(date / 3600)
        let tem = date % 3600
        let mins = parseInt(tem / 60) > 9 ? parseInt(tem / 60) : '0' + parseInt(tem / 60)
        let seconds = tem % 60 > 9 ? tem % 60 : '0' + tem % 60
        return hours + ':' + mins +  ':' + seconds
      },

      //获取车辆列表
      async onDeviceNameList() {
          if(this.bidId!== null){
            let listCarData = await this.$api_hw.historyVideo_getRealTimeCarInfoList({
              bidId: this.bidId[1],
              queryContent: null,
            })
            // 深度克隆，不直接引用后台的值
            this.carList = this.$_.cloneDeep(listCarData)
            // 调用递归, 获取所有公司下的车辆
            this.carList = this.$utils.getTreeAllData(this.carList)
          } else {
            this.$message({
              message: '请选择标段',
              type: 'warning'
            });
          }
      },

    // 请求车辆的历史视频记录
    async clickInquire(){
			if(this.deviceId === null || this.deviceId === ''){
				this.$message.error('请选择车辆');
			}else {
        this.tableLoading = true
        try {
          this.allVideoUrl = []
          this.videoHistoryData = []
          //await this.getJobInformationTableList()
          await this.getAlarmTableList()
          let videoData = await this.$api_hw.historyVideo_list({
            hvRequestVOIOV: {
              deviceId: this.deviceId,
              chn: 0,
              year: this.InquireYear + '',
              mon: this.InquireMon + '',
              day	: this.InquireDay + '',
              beg: this.start,
              end: this.end,
              arm1: 0,
              arm2: 0,
              res: this.res,
              stream: this.stream,
              store: this.store,
            }
          })
          this.videoHistoryData = videoData
          this.videoHistoryData.forEach(e=>{
            e.plateNumber = this.DevIDNO.plateNumber
          })
          this.videoHistoryData.forEach(e=>{
            e.status = false
          })
        // eslint-disable-next-line no-empty
        }catch (e) {
        }
          this.tableLoading = false
        }
      },
      // 时:分:秒转秒数
      timeToSec(time) {
        var s = '';
        var hour = time.split(':')[0];
        var min = time.split(':')[1];
        var sec = time.split(':')[2];
        s = Number(hour*3600) + Number(min*60) + Number(sec);
        return s;
      },
      // 拆分用户选择的年月日
      dateInquire (){
        if (!this.videoDate) return
        this.InquireYear = Number( this.videoDate.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$1') )
        this.InquireMon = Number( this.videoDate.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$2') )
        this.InquireDay = Number( this.videoDate.replace(/(\d{4})-(\d{2})-(\d{2})/g, '$3') )
      },
      dateInquires (){
        let newDate = new Date()
        this.InquireYear = newDate.getFullYear()
        this.InquireMon = newDate.getMonth()+ 1
        this.InquireDay = newDate.getDate()
      },
      // 拆分用户选择的时分秒
      specificTimeInquire(){
        if (!this.specificTime){
          this.start = 0
          this.end = 86399
          return
        }
        this.start = this.timeToSec(this.specificTime[0])
        this.end = this.timeToSec(this.specificTime[1])
      },


      // 请求作业信息
      async getJobInformationTableList () {
        let date = new Date()
        if((((this.$dayjs(this.videoDate).valueOf()).toString()).substring(0,8)) * 100000 === (((this.$dayjs(date).valueOf()).toString()).substring(0,8)) * 100000) {
          this.newStartTime = (((this.$dayjs(this.videoDate).valueOf()).toString()).substring(0,8)) * 100000 + this.timeToSec(this.specificTime[0]),
          this.newEndTime = (((this.$dayjs(this.videoDate).valueOf()).toString()).substring(0,8)) * 100000 + this.timeToSec(this.specificTime[1]),
          this.jobInformationTable.query = {
            bidId: this.bidId[1],
            objectType: 0,
            startTime: this.newStartTime,
            endTime: this.newEndTime,
          }
        }else {
          this.newStartTime = this.$dayjs(this.videoDate + " " + this.specificTime[0]).valueOf()
          this.newEndTime = this.$dayjs(this.videoDate + " " + this.specificTime[1]).valueOf()
          this.jobInformationTable.query = {
            bidId: this.bidId[1],
            objectType: 0,
            startTime: this.newStartTime,
            endTime: this.newEndTime,
          }
        }
        await this.$search(this.jobInformationTable)
      },

      //切换tab
      handleClick(tab, event) {
        //console.log(tab, event);
      },

      // 切换屏幕
      clickSwitch (v) {
        //if (this.allVideoUrl.length === 0) return
        if (v === this.screenNum) return
        this.allVideoUrl = []
        this.screenNum = v
       // this.playVideo()
      },
      // 点击播放
      async playVideo(item, idx){
        if (!this.unFlash()) {
          this.$notify({
            title: '系统警告',
            message: '系统检测到该网页未允许运行flash,这会导致视频无法播放,请在设置中打开flash',
            type: 'warning'
          });
          return
        }

        // let videoHistoryUrl = await this.$api.playHistoryVideo({
        //   deviceName: item.deviceName,
        //   channelList: Array.from({length: this.screenNum}, (v, i) => i + 1),
        //   begin: item.beginTime,
        //   end: item.endTIme
        // })
        let videoHistoryUrl = await this.$api.playHistoryVideo({
          deviceName: item.deviceName,
          channelList: [item.channel],
          begin: item.beginTime,
          end: item.endTime
        })
        this.allVideoUrl = []

        setTimeout(() =>{
          this.allVideoUrl = Object.keys(videoHistoryUrl).map((k) => videoHistoryUrl[k])
          this.videoHistoryData.forEach(e=>{
            e.status = false
          })
          item.status = !item.status
          this.videoHistoryData.splice(idx, 1, item)
        },100)

      },
      // 判断网页是否允许运行flash
      unFlash(){
        let flashObj;
        if(typeof window.ActiveXObject != "undefined"){
          flashObj = new ActiveXObject("ShockwaveFlash.ShockwaveFlash");
        }else{
          flashObj = navigator.plugins['Shockwave Flash'];
        }
        return flashObj ? true : false;
      },
      formatDate(row, column) {
        let date = new Date(parseInt(row));
        let Y = date.getFullYear() + '-';
        let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
        let m = date.getMinutes()  < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      },

      //获取deviceid
      onDeviceId(event)  {
        for(let i =0; i<this.carList.length; i++){
          if(this.carList[i].deviceName === event) {
            this.deviceIdList =  this.carList[i].device
          }
        }
      },
      // 请求报警列表
      async getAlarmTableList () {
        this.alarmTable.query = {
          deviceId: this.deviceIdList,
          startTime: this.newStartTime,
          endTime: this.newEndTime,
          alarmIdentifierList: [],
          dealStatus: null,
          alarmType: null,
          dealName: null,
          queryContent: null,
        }
        await this.$search(this.alarmTable)
      },
      async goPreview (val) {
        if (val.attache === 0) return
        this.dialogFormVisible = true
        let videoData = await this.$api.findFileByPrefix({
          tenantId: val.tenantId,
          attacheKey: val.attacheKey
        })
        this.playerOptions.images = videoData[0].images
        this.playerOptions.sources = videoData[0].sources
        this.videoUrl = this.playerOptions.sources[0].src
      },
      goNull() {},
      goDetail (val) {
        this.$router.push({
          path:'/alarm-management/alarm-center/alarm-dispose-details',
          query: {
            deviceId: val.deviceId,
            alarmStartTime: val.alarmStartTime,
            alarmType: val.alarmType,
            tenantId: val.tenantId,
            alarmName: val.alarmIdentifier
          }
        })
      },
    },
    computed: {
      mainStyle () {
        if (this.screenNum === 4) return 'width: calc(100% / 2);height:calc(100% / 2)'
        if (this.screenNum === 9) return 'width: calc(100% / 3);height:calc(100% / 3)'
        return 'width: 100%;height:100%'
      }
    },
    async mounted () {
      //获取所属标段下拉
      this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
      //获取车辆信息
      //let listCarData = await this.$api.getRealTimeCarInfoList({},)
      // 深度克隆，不直接引用后台的值
      //this.carList = this.$_.cloneDeep(listCarData)
      // 调用递归, 获取所有公司下的车辆
      //this.carList = this.$utils.getTreeAllData(this.carList)

      //日期初始化
      this.dateInquires()
      //判断是否打开flash
      this.unFlash()

      //获取实时监管跳转过来的数据
      if(this.$route.query.type === 1) {
        //请求车辆列表
        let listCarData = await this.$api_hw.historyVideo_getRealTimeCarInfoList({
          bidId: this.$route.query.bidId,
          queryContent: null,
        })
        // 深度克隆，不直接引用后台的值
        this.carList = this.$_.cloneDeep(listCarData)
        // 调用递归, 获取所有公司下的车辆
        this.$nextTick(() =>{
          this.carList = this.$utils.getTreeAllData(this.carList)
        })
        


        //请求视频列表
        this.tableLoading = true
        try {
          this.allVideoUrl = []
          this.videoHistoryData = []
          //await this.getJobInformationTableList()
          await this.getAlarmTableList()
          let videoData = await this.$api_hw.historyVideo_list({
            hvRequestVOIOV: {
              deviceId: this.deviceId,
              chn: 0,
              year: this.InquireYear + '',
              mon: this.InquireMon + '',
              day	: this.InquireDay + '',
              beg: this.start,
              end: this.end,
              arm1: 0,
              arm2: 0,
              res: this.res,
              stream: this.stream,
              store: this.store,
            }
          })
          this.videoHistoryData = videoData
          this.videoHistoryData.forEach(e=>{
            e.plateNumber = this.DevIDNO.plateNumber
          })
          this.videoHistoryData.forEach(e=>{
            e.status = false
          })
          
        }catch (e) {
        }
        this.tableLoading = false


        //请求作业信息列表
        let date = new Date()
        if((((this.$dayjs(this.videoDate).valueOf()).toString()).substring(0,8)) * 100000 === (((this.$dayjs(date).valueOf()).toString()).substring(0,8)) * 100000) {
          this.newStartTime = (((this.$dayjs(this.videoDate).valueOf()).toString()).substring(0,8)) * 100000 + this.timeToSec(this.specificTime[0]),
          this.newEndTime = (((this.$dayjs(this.videoDate).valueOf()).toString()).substring(0,8)) * 100000 + this.timeToSec(this.specificTime[1]),
          this.jobInformationTable.query = {
            bidId: this.$route.query.bidId,
            objectType: 0,
            startTime: this.newStartTime,
            endTime: this.newEndTime,
          }
        }else {
          this.newStartTime = this.$dayjs(this.videoDate + " " + this.specificTime[0]).valueOf()
          this.newEndTime = this.$dayjs(this.videoDate + " " + this.specificTime[1]).valueOf()
          this.jobInformationTable.query = {
            bidId: this.$route.query.bidId,
            objectType: 0,
            startTime: this.newStartTime,
            endTime: this.newEndTime,
          }
        }
        await this.$search(this.jobInformationTable)

        //请求报警列表
        this.alarmTable.query = {
          deviceId: [this.$route.query.deviceId],
          startTime: this.newStartTime,
          endTime: this.newEndTime,
          alarmIdentifierList: [],
          dealStatus: null,
          alarmType: null,
          dealName: null,
          queryContent: null,
        }
        await this.$search(this.alarmTable)
      }
    },

  }
</script>
<style lang='sass' scoped>
.font
  font-family: MicrosoftYaHei
/deep/ .el-form--inline
          .el-form-item
            margin-right: 30px
/deep/ .el-form-item__label
          color: #262626
/deep/ .video-js .vjs-big-play-button
          left: 50%
          top: 50%
/deep/ .el-table thead
        color: rgba(38, 38, 38, 1)
/deep/ .el-table th > .cell
        font-size: 14px
        color: rgba(38, 38, 38, 1)
/deep/ .el-date-editor .el-range-input
	margin-left: 2px
/deep/ .el-table .cell
        font-size: 14px
        color: rgba(102, 102, 102, 1)
/deep/ .el-form-item
        width: auto

/deep/ .el-date-editor .el-range-separator
    width: 6%

/deep/ .el-date-editor .el-range-input
    width: 36%

/deep/ .el-cascader-menu
    min-width: 270px

.video
    box-sizing: border-box
    width: calc(100% - 380px)
    height: 100%
    display: inline-block
    vertical-align: top
    background: #fff
    &_view
      width: 100%
      height: 450px
      line-height: 450px
      text-align: center
      background-color: #333
      color: #fff

.map-video-bottom
  width: 100%
  height: 30px
  line-height: 30px
  @extend %clearfix
  i
    color: $shallowBule
    /*transition: color .35s*/
    &:hover
      color: #3399FF
  .active
    color: #3399FF

.bg-danger-style
  background: white
  -webkit-box-shadow: 1px 0 6px 0 rgba(5, 117, 230, 0.1)
  box-shadow: 1px 0 6px 0 rgba(5, 117, 230, 0.1)
  border-radius: 2px
  padding: 24px 24px 0 24px
  //overflow-x: hidden
</style>

<style lang="sass">
.main-video
  width: calc(100% - 380px)
  display: inline

  .video
    height: calc(100% - 44px)
    background-color: #000

  .video-operation
    padding: 0 10px 0 20px
    height: 44px
    padding-left: 380px
    display: flex
    align-items: center
    justify-content: space-between

    i
      color: $grayShallow2
      margin-right: 10px

    .active-icon
      color: $-color-primary-3

    .operation-item
      display: inline-block
      width: 42px
      height: 20px
      border-radius: 10px
      font-size: 12px
      text-align: center
      line-height: 20px
      border: 1px solid $grayShallow2
      transition: .35s

      & + .operation-item
        margin-left: 10px

      &:hover
        border-color: $-color-primary-3
      @extend .active-icon
</style>
